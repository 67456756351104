<template>
  <div class="main-account-manager">
    <b-card title="Quản lý QR Code chấm công">
      <b-row>
        <b-col lg="4">
          <div class="search-account-input">
            <b-form-input
                v-model="qrCodeSearchDTOSearch.name"
                type="text"
                placeholder="Nhập chuỗi mã hóa QR để tìm kiếm"
                @keyup.enter="getListAllQR"
            />
          </div>
        </b-col>
        <b-col lg="4">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="getListAllQR"
          >
            <feather-icon
                icon="SearchIcon"
                class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-school-block">
            <b-row>
              <b-col
                  lg="12"
                  style="margin: auto"
              >
                <div class="acction-add-account mb-2">
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="getFormAddNewQR"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Thêm mới</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <span style="color: green">Có tổng số {{ totalCountList }} bản ghi</span>
            <b-table
                responsive
                :items="listQR"
                :per-page="perrPage"
                :current-page="currentPage"
                :fields="fields"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(status)="data">
                <b-badge v-if="data.value === 0" variant="danger">
                  Không hoạt động
                </b-badge>
                <b-badge v-if="data.value === 1" variant="success">
                  Hoạt động
                </b-badge>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                    v-b-tooltip.hover.top="'Cập nhật'"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="updateQR(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                    v-b-tooltip.hover.top="'Xóa'"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteQR(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <template>
              <b-pagination
                  v-model="currentPage"
                  class="float-right"
                  :per-page="perrPage"
                  hide-goto-end-buttons
                  :total-rows="rows"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BPagination, BBadge,
} from 'bootstrap-vue'

import { required, max } from '@validations'

import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      timeSpans: [],
      qrCodeSearchDTOSearch: {
        pageIndex: null,
        pageSize: null,
        name: null,
        status: null,
      },
      listQR: [
      ],
      currentPage: 1,
      perrPage: 10,
      required,
      max,
      totalRecordsList: 0,
      totalCountList: null,
      fields: [
        {
          key: 'index', label: 'Id', thClass: 'custom-th-index',
        },
        {
          key: 'qrCode', label: 'Chuỗi mã hóa QR',
        },
        {
          key: 'status', label: 'Trạng thái', thClass: 'custom-th-status',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-action-account', tdClass: 'custom-td-action-account',
        },
      ],
      listPerPage: [
        3, 5, 10,
      ],
    }
  },
  computed: {
    rows () {
      if (this.listQR) {
        return this.listQR.length
      }
    },
  },
  created() {
    this.getListAllQR()
  },
  methods: {
    getListAllQR() {
      this.$crm.post('qr-code/find-by-condition', this.qrCodeSearchDTOSearch).then(response => {
        if (response.data.listData) {
          this.listQR = response.data.listData
          this.totalCountList = this.listQR.length
        }
      })
    },
    getFormAddNewQR() {
      this.$router.push('/add-qr-code')
    },
    updateQR(item) {
      this.$router.push({
        name: 'update-qr-code',
        query: {
          id: item.id,
        },
      })
    },
    deleteQR(item) {
      this.$swal({
        title: 'Xóa QR chấm công',
        text: `Bạn có muốn xóa QR chấm công ${item.qrCode}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`qr-code/delete/${item.id}`).then(res => {
            this.getListAllQR()
            this.$swal({
              icon: 'success',
              title: 'Xóa QR chấm công!',
              text: 'Xóa QR chấm công thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/account';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
